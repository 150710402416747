import * as React from "react"
import { Helmet } from 'react-helmet';


//styles
import "../styles/css/styles.css"

import defaults from "../components/dafaults"
import config from "../utils/config"


// components
import Header from "../components/Header"
import Footer from "../components/Footer"
import Search from "../components/Search/index"
import Banner from "../components/Banner"
import Slider from "../components/Slider"
import List from "../components/List"
import Icon from "../components/Icon"

import bannerDesktop from "../../assets/images/desktop.main-banner.jpg"
import bannerMobile from "../../assets/images/mobile.main-banner.jpg"

import applestore from "../../assets/images/icons/apple-store.svg"
import googleplay from "../../assets/images/icons/google-play.svg"
import Pagination from "../components/Pagination"

export default function DicasTecnicas() {

  const [ lancamentos, setLancamentos ] = React.useState(null)
  const [ dicas, setDicas ] = React.useState(null)
  const [ currentPage ,  setCurrentPage ] = React.useState(1);
  const [ load, setLoad ] = React.useState(false)
  const [ total , setTotal ] = React.useState(0);

  React.useLayoutEffect(() => {
      const requestOptions = {
        method: 'GET', 
        redirect: 'follow'
      };
    
      fetch(`${defaults.api.rest}posts?postType=dado-tecnico&per_page=12&page=${currentPage}&_embed`, requestOptions)
      .then(response => {
        setTotal(response.headers.get('x-wp-total'));
        response.json();
      })
      .then(result => {
        if(dicas == null || dicas == undefined) {
          if(result != undefined) {
            setDicas(result)
          }
        }
      })
      .catch(error => console.log('error', error));
  })

  React.useEffect(() => {
    setLoad(true)

    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    
    fetch(`${defaults.api.rest}posts?postType=dado-tecnico&per_page=12&page=${currentPage}&_embed`, requestOptions)
    .then(response => response.json())
    .then(result => {
        setDicas(result)
        setLoad(false)
    })
    .catch(error => console.log('error', error));
  }, [currentPage])


  const banners = [
    {
      title: "Banner Principal",
      imageDesktop: bannerDesktop,
      imageMobile: bannerMobile,
      link: "/teste"
    },
    {
      title: "Banner Principal",
      imageDesktop: bannerDesktop,
      imageMobile: bannerMobile,
      link: "/teste"
    }
  ]

  const lancamentoConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };


  const dicasConfig = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return(
    <>
      <Helmet>
          <title>Dicas Técnicas - Wega</title>
          <link rel="shortcut icon" href={config.favicon} type="image/x-icon"></link>
      </Helmet>
      <Header />
      {/* <Search /> */}
        <section className="dicasPage">
          <div id="top" className="container dicas-title">
              <h2>Dicas Técnicas</h2>
              {/* <div className="navigation">
                <a href="/dicas-tecnicas">Ver todas as dicas</a>
              </div> */}
          </div>
          <div className="dicas-list">
            <List datas={dicas} buttonLabel="Saiba Mais" path="/dicas" load={load} />
            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage}  totalPages={total} />
          </div>
        </section>
      <Footer />
    </>
  )
}

export const Head = () => <title>Wega Motors</title>